import React, { useEffect, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player/lazy"
import { useAnimation, motion, AnimatePresence } from "framer-motion"
import { useInView } from "react-intersection-observer"

const sectionAnim = {
  // initial: {
  //   y: 20,
  // },
  visible: {
    // y: 0,
    transition: {
      when: "beforeChildren",
      delayChildren: 0.4,
      staggerChildren: 0.1,
      duration: 1,
    },
  },
}

const sectionItem = {
  initial: {
    opacity: 0,
    y: 10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
      delayChildren: 0.4,
      staggerChildren: 0.15,
      duration: 0.3,
    },
  },
}

const sectionImage = {
  initial: {
    scale: 1.1,
  },
  visible: {
    scale: 1,
    transition: {
      when: "beforeChildren",
      delayChildren: 0.4,
      staggerChildren: 0.1,
      duration: .6
    },
  },
}

const ListingHeader = ({ data }) => {
  const { heading, description, image } = data
  console.log("LP HEADER", data)

  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 0.5,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.section
      className="news-post__header relative h-[60vh] overflow-hidden"
      ref={ref}
      animate={controls}
      initial="initial"
      variants={sectionAnim}
    >
      <div className="news-post__header-overlay absolute inset-0 bg-neutral-900/30 z-10"></div>
      {image.asset && (
        <GatsbyImage
            image={image.asset.gatsbyImageData}
            alt={image.asset.altText}
            className="h-full w-full"
          />
      )}
      <div className="news-post__header-content absolute z-20 inset-0 flex items-center justify-center text-white">
        <div className="container flex justify-center items-center">
          {/* <motion.h1 variants={sectionItem} className="page-header uppercase font-bold">{heading}</motion.h1>
          <motion.p variants={sectionItem}>{description}</motion.p> */}
        </div>
      </div>
    </motion.section>
    // <section className="relative text-white" data-scroll-section data-scroll>
    //   <div className="aspect-video pointer-events-none h-screen">
    //     <ReactPlayer
    //       url="https://vimeo.com/562088579"
    //       controls={false}
    //       width="100%"
    //       height="100%"
    //       playing={true}
    //       playsinline={true}
    //       volume={0}
    //       mute={true}
    //     />
    //   </div>
    //   <div className="absolute inset-0 flex items-center justify-center">
    //     <h1 className="display uppercase">{heading}</h1>
    //   </div>
    // </section>
  )
}

export default ListingHeader
