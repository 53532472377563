import React from "react"
import { Link } from "gatsby"
import "./pagination.scss"

const Pagination = ({ pageContext }) => {
  console.log("Pagination", pageContext)

  const { numPages, currentPage, type } = pageContext

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prev = currentPage - 1 === 1 ? `/${type}` : `/${type}/${currentPage - 1}`
  const next = currentPage + 1

  const prevButton = () => {
    if (isFirst) {
      return <span className="text-neutral-400">Previous</span>
    } else {
      return (
        <span>
          <Link to={prev} rel="prev">
            Previous
          </Link>
        </span>
      )
    }
  }

  const nextButton = () => {
    if (isLast) {
      return <span className="text-neutral-400">Next</span>
    } else {
      return (
        <span>
          <Link to={`/${type}/${next}`} rel="next">
            Next
          </Link>
        </span>
      )
    }
  }

  return (
      <div className="pagination">
        {prevButton()}
        <span>
          <ul className="flex gap-8">
            {Array.from({ length: numPages }, (_, i) => (
              <li
                key={`pagination-number${i + 1}`}
                className={ i + 1 === currentPage ? "pagination-num__item num-active" : "pagination-num__item"}
              >
                <Link to={`/${type}/${i === 0 ? "" : i + 1}`}>{i + 1}</Link>
              </li>
            ))}
          </ul>
        </span>

        {nextButton()}
      </div>
  )
}

export default Pagination
