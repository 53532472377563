import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Pagination from "../components/pagination"
import ListingHeader from "../components/listingHeader"
import TextTruncate from 'react-text-truncate';

const blogListingTemplate = props => {
  const { pageContext, data } = props
  const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } =
    pageContext

  const settings = data.settings.nodes[0]
  console.log("pageContext", pageContext)
  console.log("data", data)

  console.log("blogsetitngs", settings)
  return (
    <Layout pageSeo={settings} className="bg-primary">
      <ListingHeader data={settings} />

      <section className="-mt-16" data-scroll-section data-scroll>
        <div className="container">
          <div className="news-listing__list grid grid-cols-1 lg:grid-cols-3 gap-4 p-4 rounded-md text-white">
            {data.posts.edges.map((post, index) => (
              <article className="rounded-lg overflow-hidden bg-primary-2 text-white flex flex-col">
                <div className="mb-2">
                  {post.node.featuredImage && (
                    <GatsbyImage
                      image={post.node.featuredImage.asset.gatsbyImageData}
                      alt={post.node.featuredImage.asset.altText}
                      className="object-cover w-full h-full aspect-w-1 aspect-h-1 lg:aspect-w-16 lg:aspect-h-9 z-10"
                    />
                  )}
                  
                </div>
                
                <div className="p-4 h-full flex flex-col">
                  {/* <div className="flex justify-between">
                    <p>Category</p>
                    <p>Date</p>
                  </div> */}
                  {post.node.blogCategory && (
                    <ul className="flex space-x-1 mb-2">
                    {post.node.blogCategory.map((item, index) => {
                      return (
                        <li
                          className="inline-block px-3 py-1 text-xs font-semibold text-black bg-primary-3 rounded-full"
                        >
                          <Link to={item.slug.current}>
                            {item.title}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                  )}
                  
                  
                  <h2 className="text-xl font-semibold mb-2">{post.node.title}</h2>
                  <div>
                  {post.node.excerpt && (
                    <TextTruncate
                      line={2}
                      element="p"
                      truncateText="…"
                      text={post.node.excerpt}
                  />  
                  )}
                  </div>
                  
                  
                  <div className="flex items-end	justify-end h-full w-full">
                    <div>
                      <Link
                        to={post.node.slug.current}
                        className="btn-primary mt-4"
                      >
                        Read
                      </Link>
                    </div>
                 
                  </div>
                  
                </div>
              </article>
            ))}
          </div>

          <Pagination pageContext={pageContext} />
        </div>
      </section>
    </Layout>
  )
}

export default blogListingTemplate

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    posts: allSanityBlog(
      sort: { fields: _createdAt, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          _createdAt(formatString: "MMMM DD, YYYY")
          _id
          _key
          _type
          _updatedAt(formatString: "MMMM DD, YYYY")
          id
          title
          excerpt
          slug {
            _key
            _type
            current
          }
          featuredImage {
            _type
            _key
            asset {
              altText
              gatsbyImageData
            }
          }
          blogCategory {
            title
            slug {
              current
            }
          }
        }
      }
    }
    settings: allSanityBlogSettings {
      nodes {
        _key
        _type
        heading
        description
        id
        image {
          _key
          _type
          asset {
            altText
            gatsbyImageData
          }
        }
        metaDesc
        metaTitle
        shareGraphic {
          asset {
            url
          }
        }
      }
    }
  }
`
